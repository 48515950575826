import { decode, JwtPayload } from "jsonwebtoken"
import { GetServerSidePropsContext } from "next"
import { ApiClient, ClientFromContext } from "@/core/services/aoeu"
import { FlagsPayload } from "~/services/aoeu/models"
import logger from "~/util/logger"

const getEnabledFeatureNames = (features: FlagsPayload): string[] =>
  features.flatMap(feature =>
    feature.enabled ? [feature.feature.name] : [],
  ) ?? []

type AoeuJwtPayload = JwtPayload & {
  userId?: string
  featureServiceId?: string
}

const decodeJwt = (jwt: string): AoeuJwtPayload | null => {
  try {
    return decode(jwt, { json: true })
  } catch (error) {
    return null
  }
}

/**
 * Retrieve enabled feature for a given user.
 */
export async function getUserFeaturesFromContext(
  context: GetServerSidePropsContext,
): Promise<Set<string>> {
  const accessToken = context?.req?.cookies["aoeu-session"]
  return getUserFeatures(accessToken, context)
}

const featureFlagCache = new Map<string, Set<string>>()
const DEFAULT_CACHE_TTL = 15 * 1000

export async function getUserFeatures(
  accessToken?: string,
  context?: GetServerSidePropsContext,
): Promise<Set<string>> {
  if (!accessToken) {
    return new Set()
  }

  const api = ClientFromContext(ApiClient, context)
  const decodedToken = decodeJwt(accessToken)

  const featureServiceIdentifier = decodedToken?.featureServiceId

  if (!featureServiceIdentifier) {
    logger
      .withScope({
        tags: {
          caller: "getUserFeaturesFromContext",
          token: accessToken,
        },
      })
      .warn("Failed to properly decode JWT token: No feature service ID found")

    return new Set()
  }

  if (featureFlagCache.has(featureServiceIdentifier)) {
    return featureFlagCache.get(featureServiceIdentifier) ?? new Set()
  }

  const userTraitsResponse = await api.getUserTraits({
    featureServiceId: featureServiceIdentifier,
  })

  const enabledFeaturesForUser = new Set(
    getEnabledFeatureNames(userTraitsResponse.data.flags),
  )

  featureFlagCache.set(featureServiceIdentifier, enabledFeaturesForUser)
  setTimeout(
    () => featureFlagCache.delete(featureServiceIdentifier),
    DEFAULT_CACHE_TTL,
  )

  return enabledFeaturesForUser
}
