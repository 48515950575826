import {
  createContext,
  ReactElement,
  ReactNode,
  useContext,
  useEffect,
  useRef,
} from "react"
import { useRouter } from "~/hooks/use-router"
import logger from "~/util/logger"

export const PreviousUrlContext = createContext<string | null>(null)

export const PreviousUrlProvider = ({
  children,
}: {
  children: ReactNode
}): ReactElement => {
  const router = useRouter()
  const ref = useRef<string | null>(null)

  const handleRouteChange = (url: string) => (ref.current = url)

  useEffect(() => {
    router.events.on("routeChangeComplete", handleRouteChange)

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange)
    }
  }, [router.events])

  return (
    <PreviousUrlContext.Provider value={ref.current}>
      {children}
    </PreviousUrlContext.Provider>
  )
}

export const usePreviousUrl = (): string | null => {
  const context = useContext(PreviousUrlContext)

  useEffect(() => {
    if (context === null) {
      // sometimes this can be null, like when the page is first loaded
      // or when the user opens a link in a new tab
      logger.warn("usePreviousUrl: context is null")
    }
  }, [context])

  return context
}
